// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { hideVisually } from 'polished';
import SEO from '@src/components/SEO';
import Layout from '../components/Layout';

// styles
import { magnifyStyle } from '../styles/global';

// views
import HeroSection from '../views/ConsumersPageView/HeroSection';
import IntroSection from '../views/ConsumersPageView/IntroSection';
import ProductsSection from '../views/ConsumersPageView/ProductsSection';
import RetailersSection from '../views/ConsumersPageView/RetailersSection';

// TODO: Translate when texts will be available
const ConsumersPage = ({ data }) => {
  const {
    products: { nodes: products },
    categories: { nodes: categories },
    retailers: { nodes: retailers },
  } = data;

  const langLinks = [{ langKey: 'fr', href: '/particuliers' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        lang='en'
        langLinks={langLinks}
        title='Consumers'
        description='Need durable, top-quality household storage solutions? Permafil products are robust, versatile and adaptable to your space.'
      />

      <HeroSection>
        <h1>
          Consumers<span css={hideVisually()}> - </span>
          <small>Storage products for your everyday life</small>
        </h1>
      </HeroSection>

      <IntroSection>
        <h2 css={hideVisually}>Why choosing Permafil</h2>

        <p css={magnifyStyle}>
          Permafil has specialized in the design and installation of home
          storage products and closet organizers since 1988.
        </p>

        <p>
          We have earned a great reputation as our products are recognized
          across the continent for their quality and durability.
        </p>

        <p>
          Permafil offers a range of home storage products and closet organizers
          that are proudly made in Quebec at our Sainte-Marguerite factory in
          Beauce. They are available at several large hardware and renovation
          stores across the province.
        </p>
      </IntroSection>

      <ProductsSection products={[...categories, ...products]} />

      <RetailersSection retailers={retailers} />
    </Layout>
  );
};

ConsumersPage.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
    retailers: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default ConsumersPage;

export const query = graphql`
  query consumersPageQuery {
    products: allSanityProduct(
      filter: {
        targetAudience: { eq: "particular" }
        localization: { locale: { eq: "en" } }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        title
        categories {
          id
        }
        localization {
          locale
        }
        models {
          name
          pictures {
            hotspot {
              x
              y
            }
            asset {
              fluid(maxWidth: 416, maxHeight: 318) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }

    categories: allSanityProductCategory(
      filter: {
        targetAudience: { eq: "particular" }
        localization: { locale: { eq: "en" } }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        title
        targetAudience
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        localization {
          locale
        }
      }
    }

    retailers: allSanityRetailer(
      filter: { localization: { locale: { eq: "en" } } }
    ) {
      nodes {
        name
        url
        logo {
          asset {
            url
          }
        }
      }
    }
  }
`;
